import { Center, Grid } from '@lojinha/design-system'
import { MarketableKind } from '@lojinha/palantir'
import React from 'react'
import { CarouselMeal } from './carousel-meal'
import { CarouselProduct } from './carousel-product'
import { CarouselDiplayProps } from './types'

export const CarouselDisplay = ({
  id,
  kind,
  name,
  slug,
  items,
  showLastCard,
  originList,
  onArrowSlideClick,
  onSeeAllClick,
  onRenderItem,
  onItemClick,
}: CarouselDiplayProps) => {
  return (
    <Center>
      <Grid gridTemplateColumns={['repeat(24, 1fr)', 'repeat(12, 1fr)']}>
        <Grid
          gridColumn={['2 / 24', '2 / 12']}
          display="block"
          htmlAttrs={{
            style: {
              maxWidth: '100%',
            },
          }}
        >
          {kind === MarketableKind.Meal ? (
            <CarouselMeal
              id={id}
              name={name}
              slug={slug}
              items={items}
              originList={originList}
              onArrowSlideClick={onArrowSlideClick}
              onRenderItem={onRenderItem}
              onItemClick={onItemClick}
              onSeeAllClick={onSeeAllClick}
              showLastCard={showLastCard}
            />
          ) : (
            <CarouselProduct
              id={id}
              name={name}
              slug={slug}
              items={items}
              originList={originList}
              onArrowSlideClick={onArrowSlideClick}
              onItemClick={onItemClick}
              onRenderItem={onRenderItem}
              onSeeAllClick={onSeeAllClick}
              showLastCard={showLastCard}
            />
          )}
        </Grid>
      </Grid>
    </Center>
  )
}
