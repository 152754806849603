import { Box, Carousel } from '@lojinha/design-system'
import React from 'react'
import styled from 'styled-components'
import { sanitizeImage } from '@lojinha/helpers'
import { t } from '../../dictionary'
import { useWindowProperties } from '../../helpers/useWindowProperties'
import { LastCard } from '../last-card'
import { ProductCardIndividual } from '../product-card'
import { Header } from './header'
import { CarouselDiplayProps } from './types'

const CustomBox = styled(Box)`
  > a {
    text-decoration: none !important;
  }
`

export const CarouselProduct = ({
  id,
  items,
  name,
  slug,
  originList,
  onArrowSlideClick,
  onItemClick,
  onRenderItem,
  onSeeAllClick,
  showLastCard,
}: CarouselDiplayProps) => {
  const { isMobile, isTablet } = useWindowProperties()

  const slidesToShow = isMobile ? 2 : isTablet ? 3 : 4

  return (
    <Carousel
      slideToShow={slidesToShow}
      id={name}
      constrolsOnVertical={isMobile ? 'bottom' : undefined}
      constrolsOnHorizontal={isMobile ? 'center' : undefined}
      onNextSlideClick={() => onArrowSlideClick({ name, direction: 'right' })}
      onPrevSlideClick={() => onArrowSlideClick({ name, direction: 'left' })}
      header={
        <Header
          name={name}
          slug={slug}
          onLinkClick={() =>
            onSeeAllClick && onSeeAllClick({ name, isHeader: true })
          }
        />
      }
    >
      {items.map((item, index) => {
        return (
          <Box key={item.id} height={['312px', '408px']}>
            <ProductCardIndividual
              id={item.id}
              kind={item.kind}
              title={item.name}
              badges={item.itemTags.nodes}
              imageUrl={sanitizeImage({
                imageUrl: item.imageUrl,
                size: 'medium',
              })}
              isAgeRestricted={item.isAgeRestricted}
              isAvailable={item.isAvailable}
              price={item.price || 0}
              priceFrom={item.priceFrom || 0}
              weight={t.weightAndUnit(item.weight, item.unit)}
              quantity={0}
              sku={item.sku}
              imageAriaLabel={item.name}
              eventList={originList}
              positionInList={index}
              onRenderItem={() =>
                onRenderItem &&
                onRenderItem({
                  id: item.id,
                  name: item.name,
                  positionInList: index,
                  price: item.price ?? 0,
                  listName: originList,
                  isAvailable: item.isAvailable,
                })
              }
              onItemClick={() =>
                onItemClick &&
                onItemClick({
                  id: item.id,
                  name: item.name,
                  positionInList: index,
                  price: item.price ?? 0,
                  originList: originList,
                  isAvailable: item.isAvailable,
                })
              }
              slug={item.seo.url}
            />
          </Box>
        )
      })}
      {showLastCard && onSeeAllClick && (
        <CustomBox key={`last-card-${id}`} height={['312px', '408px']}>
          <LastCard
            slug={slug}
            onCardClick={() => {
              onSeeAllClick({ name })
            }}
            text={t.lastCardText(name)}
          />
        </CustomBox>
      )}
    </Carousel>
  )
}
